const manageAttentionMessage = function () {
  let topAttentionMessage = document.querySelector('.attention');
  let bottomAttentionMessage = document.querySelector('.attention--bottom');
  let header = document.querySelector('.header');
  let footer = document.querySelector('.footer');
  const headerMenu = document.querySelector('[data-mobile-menu="header"]');
  const footerMenu = document.querySelector('[data-mobile-menu="footer"]');

  if (topAttentionMessage || bottomAttentionMessage) {
    document.body.addEventListener('click', (evt) => {
      if (evt.target.closest('.attention--bottom .attention__close')) {
        bottomAttentionMessage.remove();
      } else if (evt.target.closest('.attention .attention__close')) {
        topAttentionMessage.remove();
      }
    });

    const setHeaderOffset = (offset = 0) => {
      if (header) {
        header.style.paddingTop = offset + 'px';
      }

      if (headerMenu && window.matchMedia('(max-width: 1279px)').matches) {
        headerMenu.style.setProperty('--offset-top', offset + 'px');
      } else {
        headerMenu.style.setProperty('--offset-top', 0);
      }

      if (footerMenu && window.matchMedia('(max-width: 767px)').matches) {
        footerMenu.style.setProperty('--offset-top', offset + 'px');
      } else {
        footerMenu.style.setProperty('--offset-top', 0);
      }
    };

    const setFooterOffset = (offset = 0) => {
      if (footer) {
        footer.style.paddingBottom = offset + 'px';
      }
    };

    const fixHeaderOffset = () => {
      if (topAttentionMessage) {
        setHeaderOffset(topAttentionMessage.clientHeight);
      }
    };

    const fixFooterOffset = () => {
      if (bottomAttentionMessage) {
        setFooterOffset(bottomAttentionMessage.clientHeight);
      }
    };

    const fixScrollBarOffset = () => {
      const scrollBarOffset = window.innerWidth - document.documentElement.clientWidth;
      const messageWidth = `calc(100vw - ${scrollBarOffset}px)`;
      if (topAttentionMessage) {
        topAttentionMessage.style.maxWidth = messageWidth;
      }

      if (bottomAttentionMessage) {
        bottomAttentionMessage.style.maxWidth = messageWidth;
      }
    };

    fixHeaderOffset();
    fixFooterOffset();
    fixScrollBarOffset();

    let windowWidth = window.innerWidth;

    window.addEventListener('resize', () => {

      if (windowWidth !== window.innerWidth) {
        windowWidth = window.innerWidth;

        fixHeaderOffset();
        fixFooterOffset();
        fixScrollBarOffset();
      }
    });
  }
};

export {manageAttentionMessage};
